import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container, IconButton, Link, Modal } from '@mui/material';
import { Facebook, Instagram, WhatsApp } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import TwitterIcon from '@mui/icons-material/X';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AboutModal from '../components/AboutModal';
import img1 from '../assets/Carousel/1.jpg';
import img2 from '../assets/Carousel/2.jpg';
import img3 from '../assets/Carousel/3.jpg';
import '../App.css'; 


const LandingPage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box id='landing-wrap' sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'row', height: '100vh' }}>

      <Box sx={{ flex: 2 }}>
        <Carousel
          autoPlay
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          className="carousel-root"
        >
          <div>
            <img id='slide-img' src={img1} alt="Slide 1" />
          </div>
          <div>
            <img id='slide-img' src={img2} alt="Slide 2" />
          </div>
          <div>
            <img id='slide-img' src={img3} alt="Slide 3" />
          </div>
        </Carousel>
        <AboutModal />
      </Box>

      <Box id='form-wrap' sx={{ flex: 1.9, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#00002C' }}>
        <Container id='form-container' maxWidth="xs">
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography id='h4-desktop' variant="h4" gutterBottom sx={{ color: '#ffffff', fontFamily: 'Roboto, sans-serif' }}>
              Welcome to <br/>Logitap Freight Portal<br/><br/>
            </Typography>
            <Typography id='h4-mobile' variant="h4" gutterBottom sx={{ color: '#ffffff', fontWeight:'800', fontSize:'1.9rem', fontFamily: 'Roboto, sans-serif' }}>
              Welcome!
            </Typography>
            {/* <Typography id='h4-mobile' variant="h4" gutterBottom sx={{ color: '#ffffff', fontSize:'1.2rem' }}>
              Log in to continue
            </Typography> */}
            <TextField
              id='form-email'
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              autoComplete="email"
              autoFocus
              slotProps={{
                inputLabel: {
                  style: { color: 'grey' }, 
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white', 
                  '& fieldset': {
                    borderColor: '#1976d2', 
                  },
                  '&:hover fieldset': {
                    borderColor: '#1976d2', 
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                  '& .MuiInputBase-input': {
                    '::placeholder': {
                      color: 'grey', 
                      opacity: 1, 
                    },
                    color: 'white', 
                  },
                },
              }}
            />
            <TextField
              id='form-pass'
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              autoComplete="current-password"
              slotProps={{
                inputLabel: {
                  style: { color: 'grey' }, 
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white', 
                  '& fieldset': {
                    borderColor: '#1976d2', 
                  },
                  '&:hover fieldset': {
                    borderColor: '#1976d2', 
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                  '& .MuiInputBase-input': {
                    '::placeholder': {
                      color: 'grey', 
                      opacity: 1, 
                    },
                    color: 'white', 
                  },
                },
              }}
            />
            <Button
              id='form-btn'
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: '#AD1328', color: '#ffffff' }}
            >
              Log In
            </Button>
            <Box sx={{display:'flex'}}>
              <Button href="#" variant="text" sx={{ color: '#AD1328' }}>
                Forgot password?
              </Button>
              <Typography sx={{ color: '#AD1328', marginTop:'6px' }}>&nbsp;·&nbsp;</Typography>
              <Button onClick={handleOpen} variant="text" sx={{ color: '#AD1328' }}>
                No login details?
              </Button>
              <Modal open={open} onClose={handleClose}>
                <Box 
                    sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: '#f5f5f5', 
                    color: '#00002C', 
                    p: 4,
                    maxWidth:'600px',
                    width: '80vw', 
                    maxHeight: '80vh', 
                    overflowY: 'auto', 
                    borderRadius: 2,
                    }}
                >
                  <Typography variant="h6" sx={{ color: '#AD1328', fontWeight: 'bold' }}>Hey there! We're here for you.</Typography>
                  <Typography sx={{ mt: 2 }}>
                    Due to the sensitive nature of our operations, registration is strictly for Logitap Freight clients.
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    Kindly contact us for more details, and be part of the Logitap Freight today!  
                  </Typography>
                  <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', width:'100%', marginTop:'8px'}}>
                    <IconButton
                      href="https://wa.me/+8619700293559" 
                      sx={{ color: '#AD1328' }}
                      aria-label="WhatsApp"
                      target='_blank'
                    >
                      <WhatsApp />
                    </IconButton>
                    <IconButton
                      href="mailto:info@logitapfreight.co.zw"
                      sx={{ color: '#AD1328' }}
                      aria-label="Email"
                      target='_blank'
                    >
                      <EmailIcon />
                    </IconButton>
                    <IconButton
                      href="tel:+263775051497"
                      sx={{ color: '#AD1328' }}
                      aria-label="Phone"
                      target='_blank'
                    >
                      <PhoneIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Modal>
            </Box>
         
            <Box id='socials' sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white' }}>
              {/* Website and Developer Links */}
              <Box id='socials-desk' sx={{display:'flex', flexDirection:'row'}}>
              <Link href="https://logitapfreight.co.zw" target='_blank' underline="none" sx={{ color: '#ffffff', mb: 1 }}>
                Visit our Website
              </Link>
              <Typography>&nbsp;|&nbsp;</Typography>
              <Link href="#" underline="none" sx={{ color: '#ffffff', mb: 1 }}>
                Developer
              </Link>
              </Box>
              <Box id='socials-mobile' sx={{display:'none', flexDirection:'row'}}>
              <Link href="https://logitapfreight.co.zw" underline="none" target='_blank' sx={{ color: '#ffffff', mb: 1 }}>
                Website
              </Link>
              <Typography>&nbsp;&nbsp;|&nbsp;&nbsp;</Typography>
              <Link href="#" underline="none" sx={{ color: '#ffffff', mb: 1 }}>
                Developer
              </Link>
              {/* <Typography mt={1} sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                {'Copyright © '}
                Destiny Shapers&nbsp;
                {new Date().getFullYear()} 
                <span preserve>&nbsp;</span> | <span preserve>&nbsp;</span>
                <Link href="https://www.linkedin.com/in/timothy-tinashe-murambinda/" target='_blank' rel='noreferrer'>Developer&nbsp;</Link>
              </Typography> */}
              </Box>
              {/* Social Media Icons */}
              <Box id='social-icons' sx={{ display: 'flex', gap: 2 }}>
                <IconButton
                  href="https://x.com/logitap?t=ZRihVM3mnlORBTucP3l7iw&s=09" 
                  sx={{ color: '#ffffff' }}
                  aria-label="X"
                  target='_blank'
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  href="https://www.facebook.com/profile.php?id=100063650510304&mibextid=ZbWKwL"
                  sx={{ color: '#ffffff' }}
                  aria-label="Facebook"
                  target='_blank'
                >
                  <Facebook />
                </IconButton>
                <IconButton
                  href="https://www.instagram.com/logitapfreight?igsh=MXhjenU5MGpkNm9ucw=="
                  sx={{ color: '#ffffff' }}
                  aria-label="Instagram"
                  target='_blank'
                >
                  <Instagram />
                </IconButton>
                <IconButton
                  href="https://wa.me/+8619700293559" 
                  sx={{ color: '#ffffff' }}
                  aria-label="WhatsApp"
                  target='_blank'
                >
                  <WhatsApp />
                </IconButton>
                <IconButton
                  href="mailto:info@logitapfreight.co.zw"
                  sx={{ color: '#ffffff' }}
                  aria-label="Email"
                  target='_blank'
                >
                  <EmailIcon />
                </IconButton>

                {/* Phone Icon */}
                <IconButton
                  href="tel:+263775051497"
                  sx={{ color: '#ffffff' }}
                  aria-label="Phone"
                  target='_blank'
                >
                  <PhoneIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;
