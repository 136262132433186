import React, { useState } from 'react';
import { Modal, Box, Button, Typography, Link } from '@mui/material';

const AboutModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Box
        id='learnmore-wrap'
        sx={{
          position: 'absolute',
          bottom: '0%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10, 
          backgroundColor: 'transparent',
        }}
      >
        <Button
          id='learnmore-btn'
          onClick={handleOpen}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            color: '#AD1328',
            padding: '0.75rem 1.5rem',
            fontSize: '1rem',
            fontWeight: 'bold',
            borderRadius: '30px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 6.2)',
            '&:hover': {
              backgroundColor: '#AD1328',
              color: 'white',
              fontWeight: 'bold',
            },
          }}
        >
          Learn More
        </Button>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box 
            sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#f5f5f5', 
            color: '#00002C', 
            p: 4,
            width: '80vw', 
            maxHeight: '80vh', 
            overflowY: 'auto', 
            borderRadius: 2,
            }}
        >
          <Typography variant="h6" sx={{ color: '#AD1328', fontWeight: 'bold', textAlign:'center' }}>Vision</Typography>
          <Typography sx={{ mt: 2 }}>
          To be a leading global freight forwarding company, providing efficient and reliable logistics solutions
          </Typography>
          <Typography variant="h6" sx={{ color: '#AD1328', fontWeight: 'bold', mt: 2, textAlign:'center' }}>Mission</Typography>
          <Typography sx={{ mt: 2 }}>
          To deliver exceptional customer service, optimise supply chain operations and foster long term partnerships.
          </Typography>
          <Typography variant="h6" sx={{ color: '#AD1328', fontWeight: 'bold', mt: 2, textAlign:'center' }}>More About Logitap Freight</Typography>
          <Typography sx={{ mt: 2 }}>
          With a proud legacy of seven years in the transport and logistics industry, <Link href="https://logitapfreight.co.zw" target='_blank' underline="none">Logitap Freight</Link> has established itself as a leading provider of comprehensive services in Zimbabwe and the Southern African Development Community (SADC) region. Specializing in transport and logistics, customs clearing, and import and export consultation services, we are committed to delivering excellence at every stage of the supply chain.
          </Typography>
          <Typography sx={{ mt: 2 }}>
          Our emblem, the majestic eagle, symbolizes our unwavering commitment to excellence and superiority in the services we provide. At <Link href="https://logitapfreight.co.zw" target='_blank' underline="none">Logitap Freight</Link>, we soar above industry standards, driven by a steadfast dedication to customer satisfaction and operational excellence.
          </Typography>
          <Typography sx={{ mt: 2 }}>
          Our primary objective is simple yet profound: to provide our clients with satisfaction, convenience, and reliability in every interaction. Whether you’re shipping goods domestically or across borders, our experienced team ensures that your cargo reaches its destination safely, efficiently, and on time.
          </Typography>
          <Typography sx={{ mt: 2 }}>
          Professionalism is at the core of everything we do at <Link href="https://logitapfreight.co.zw" target='_blank' underline="none">Logitap Freight</Link>. We understand the importance of handling our clients’ needs with the utmost care and attention to detail. With a focus on efficiency and quick turnaround times, we strive to exceed expectations on every order, no matter the size or complexity.
          </Typography>
          <Typography sx={{ mt: 2 }}>
          Central to our ethos is the belief that good customer care is paramount for business growth. We recognize that satisfied clients are our most valuable assets, and we prioritize building long-lasting relationships based on trust, reliability, and mutual respect. Through exemplary service and dedication to our clients’ needs, we aim not only to meet but to exceed their expectations, fostering strong partnerships that endure over time.
          </Typography>
          <Typography sx={{ mt: 2 }}>
          At <Link href="https://logitapfreight.co.zw" target='_blank' underline="none">Logitap Freight</Link>, we don’t just deliver goods; we deliver peace of mind. Trust us to handle your logistics needs with professionalism, efficiency, and care, and experience the difference that true excellence in transport and logistics services can make for your business. Welcome to a world of unparalleled service and satisfaction. 
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default AboutModal;
